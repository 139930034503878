import * as React from "react";

import { Carousel } from "../../common/Carousel";

import { TestimonialCard } from "../Card";


interface Props {
  nodes: any[]
};


export const TestimonialsCarousel: React.FC<Props> = ( props ) => {
  return (
    <Carousel
      options={{
        loop: true,
        skipSnaps: false
      }}
      buttonsEnabled
      dotsEnabled
      emblaPadding={{
        base: '48px 0 24px',
        lg: '90px 0 24px'
      }}
    >
      { props.nodes.map(( node, _index ) => (
        <TestimonialCard
          key={ _index }
          node={ node.node }
        />
      ))}
    </Carousel>
  )
}
