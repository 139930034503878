import * as React from "react";
import {
  Flex
} from "@chakra-ui/react";

import { Carousel } from "../../common/Carousel";

import { BenefitCard } from "../Card";


interface Props {
  nodes: any[]
};


export const BenefitsCarousel: React.FC<Props> = ( props ) => {
  return (
    <>
      <Carousel
        options={{
          dragFree: true,
          containScroll: "trimSnaps"
        }}
        display={{
          base: 'block',
          lg: 'none'
        }}
        dotsEnabled
        emblaPadding="0"
        layout="benefit"
        nodes={ props.nodes }
      />
      <Flex
        display={{
          base: 'none',
          lg: 'flex'
        }}
        justifyContent="space-between"
        w="100%"
      >
        { props.nodes.map( ( node, _index ) => (
          <BenefitCard
            key={ _index }
            heading={ node.node.frontmatter.heading }
            description={ node.node.frontmatter.description }
            icon={ node.node.frontmatter.icon }
          />
        ))}
      </Flex>
    </>
  )
}
