import * as React from "react";
import {
  Box,
  Divider,
  Heading,
  Text
} from "@chakra-ui/react";

import { Card } from "../../common/Card";
import { QuotationsIcon } from "../../common/ScalableVectorGraphics";


interface TestimonialProps {
  node: any
};


export const TestimonialCard: React.FC<TestimonialProps> = ( props ) => {
  return (
    <Card
      flex={{
        base: '0 0 290px',
        sm: '0 0 400px'
      }}
      margin="0 20px"
      borderRadius={{
        base: '18px'
      }}
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.06)"
      containerPadding="42px"
    >
      <Box
        position="absolute"
        top="92px"
        left="21px"
        w="102px"
        h="102px"
        background="primary.50"
        borderRadius="50%"
      />
      <QuotationsIcon
        w="28px"
        h="23px"
        color="primary.900"
      />
      <Box
        w="100%"
        h={{
          base: '400px',
          sm: '280px'
        }}
        padding="24px 0"
      >
        <Text
          color="accent.900"
          fontFamily="heading"
          fontSize="28px"
          fontWeight={ 400 }
          lineHeight="33px"
          letterSpacing="-0.3px"
        >
          { props.node.frontmatter.review }
        </Text>
      </Box>
      <Divider />
      <Box
        padding="24px 0 0"
      >
        <Heading
          as="h6"
          marginBottom="7px"
          color="accent.900"
          fontSize="19px"
          fontWeight={ 700 }
          lineHeight="21px"
        >
          { props.node.frontmatter.reviewer }
        </Heading>
        <Text
          color="accent.900"
        >
          Verified { props.node.frontmatter.source } Review
        </Text>
      </Box>
    </Card>
  )
}
