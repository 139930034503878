import * as React from "react";
import {
  Box,
  Container,
  Flex
} from "@chakra-ui/react";

import { Carousel } from "../../common/Carousel";

import { ServiceCard } from "../Card";


interface Props {
  heading?: string
  description?: string[]
  nodes: any[]
};


export const ServiceCarousel: React.FC<Props> = ( props ) => {
  return (
    <>
      <Container
        display={{
          base: 'none',
          lg: 'block'
        }}
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px',
          '2xl': '1536px'
        }}
        w="100%"
        background="background.linkwater"
        margin="50px auto 0"
        padding="0 1rem"
        borderRadius="24px"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          padding={{
            lg: '64px 20px',
            xl: '64px 90px'
          }}
        >
          { props.nodes.map(( node, _index ) => (
            <ServiceCard
              key={ _index }
              node={ node.node }
            />
          ))}
        </Flex>
      </Container>
      <Box
        display={{
          lg: 'none'
        }}
        w="100%"
        background="background.linkwater"
        margin="50px 0 0"
        padding="64px 0"
      >
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px'
          }}
          w="100%"
        >
          <Carousel
            options={
              {
                dragFree: true,
                containScroll: "trimSnaps"
              }
            }
            layout="service"
            nodes={ props.nodes }
            emblaPadding={{
              xl: '64px 90px'
            }}
          />
        </Container>
      </Box>
    </>
  )
}
