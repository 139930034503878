import * as React from "react";
import {
  LinkBox
} from "@chakra-ui/react";


interface Props {
  children: React.ReactNode
  linkBoxWidth?: object | string
  linkBoxHeight?: object | string
  margin?: object | string
};


export const LinkCard: React.FC<Props> = ( props ) => {
  return (
    <LinkBox
      position="relative"
      minW={ props.linkBoxWidth }
      w={ props.linkBoxWidth }
      minH={ props.linkBoxHeight }
      h={ props.linkBoxHeight }
      margin={ props.margin }
      transition="all 0.2s ease-in-out"
      _hover={{
        opacity: '0.7'
      }}
    >
      { props.children }
    </LinkBox>
  )
}
