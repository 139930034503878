import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  Link,
  Text
} from "@chakra-ui/react";

import {
  SectionContent
} from "../../common/Section";


interface Props {
  heading: string
};


export const CallToAction: React.FC<Props> = ( props ) => {
  return (
    <SectionContent
      justifyContent="flex-start"
      alignItems="center"
      textAlign="center"
      heading={ props.heading }
      Description={
        <Text
          w={{
            base: '100%',
            sm: '400px'
          }}
          color="accent.900"
        >
          Call us at
          &nbsp;
          <Link
            href="tel:+18587933471"
            color="primary.900"
            _hover={{
              opacity: '0.6',
              textDecoration: 'none'
            }}
          >
            +1 (858) 793-3471
          </Link>
          &nbsp;
          our Vester Pest Control services experts are here to help.
        </Text>
      }
      Button={
        <Button
          as={ GatsbyLink }
          to="/estimate"
          variant="solid"
          size="lg"
          colorScheme="primary"
          border="1px"
          borderColor="primary.900"
          borderRadius="24px"
          _hover={{
            background: 'transparent',
            color: 'primary.900'
          }}
        >
          Free Estimate
        </Button>
      }
    />
  )
}
