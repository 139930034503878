import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";


interface Props {
  heading: string
  description: string
  postDate: string
  image: IGatsbyImageData
  slug: string
};


export const BlogPostSlide: React.FC<Props> = ( props ) => {
  return (
    <LinkBox
      position="relative"
      minW={{
        base: '300px',
        xl: '350px',
        '2xl': '400px'
      }}
      w={{
        base: '300px',
        xl: '350px',
        '2xl': '400px'
      }}
      margin={{
        base: '0 2rem',
        lg: '0'
      }}
      transition="all 0.2s ease-in-out"
      _hover={{
        opacity: '0.7'
      }}
    >
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing={ 2 }
      >
        <Image
          as={ GatsbyImage }
          image={ props.image }
          objectFit="cover"
          w="100%"
          h={{
            lg: '300px',
            xl: '350px',
            '2xl': '400px'
          }}
          borderRadius="12px"
        />
        <Text
          color="accent.900"
          fontSize="12px"
          lineHeight="16px"
        >
          { props.postDate }
        </Text>
        <Heading
          as="h3"
          color="accent.900"
          fontSize="28px"
          fontWeight={ 400 }
          lineHeight="33px"
        >
          <LinkOverlay
            as={ GatsbyLink }
            to={ props.slug }
          >
            { props.heading }
          </LinkOverlay>
        </Heading>
        <Text
          color="accent.900"
          fontSize="16px"
          lineHeight="21px"
        >
          { props.description }
        </Text>
      </VStack>
    </LinkBox>
  )
}
