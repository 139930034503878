import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Flex,
  Heading,
  Image
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { Card } from "../../common/Card";


interface Props {
  heading: string
  image: IGatsbyImageData
};


export const PestCard: React.FC<Props> = ( props ) => {
  return (
    <Card
      cardWidth={{
        base: '130px',
        sm: '200px'
      }}
      cardHeight={{
        base: '140px',
        sm: '200px'
      }}
      background="white"
      borderRadius={{
        base: '8px'
      }}
      boxShadow="0px 4px 15px rgba(0, 0, 0, 0.05)"
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        h="100%"
        padding={{
          base: '20px 0',
          sm: '29px 12px'
        }}
      >
        <Image
          as={ GatsbyImage }
          image={ props.image }
          w="100%"
          h="auto"
        />
        <Heading
          as="h3"
          fontSize="19px"
          fontWeight={ 700 }
          lineHeight="21px"
        >
          { props.heading }
        </Heading>
      </Flex>
    </Card>
  )
}
