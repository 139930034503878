import * as React from "react";
import {
  Heading,
  HStack,
  ListItem,
  VStack,
  Text,
  UnorderedList,
  WrapItem
} from "@chakra-ui/react";

import { Card } from "../../common/Card";


interface Props {
  heading: string
  description?: string[]
  list?: string[]
};


export const GuaranteeCard: React.FC<Props> = ( props ) => {
  return (
    <WrapItem>
      <Card
        cardWidth={{
          base: '280px',
          md: '320px',
          xl: '400px',
          '2xl': '480px'
        }}
        cardHeight={{
          sm: '480px',
          md: '440px',
          lg: '440px',
          xl: '360px',
          '2xl': '312px'
        }}
        background="white"
        borderRadius="8px"
        containerPadding="24px"
      >
        <VStack
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={ 6 }
        >
          <Heading
            as="h3"
            fontSize="32px"
            fontWeight={ 400 }
            lineHeight="36px"
          >
            { props.heading }
          </Heading>
          { props.description &&
            <>
              { props.description.map(( paragraph, _index ) => (
                <Text
                  color="accent.900"
                  fontSize="16px"
                  fontWeight={ 400 }
                  lineHeight="21px"
                >
                  { paragraph }
                </Text>
              ))}
            </>
          }
          { props.list &&
            <UnorderedList
              stylePosition="inside"
            >
              { props.list.map(( item, _index ) => (
                <HStack
                  alignItems="flex-start"
                  spacing={ 2 }
                >
                  <ListItem></ListItem>
                  <Text
                    color="accent.900"
                    fontSize="16px"
                    fontWeight={ 400 }
                    lineHeight="21px"
                  >
                    { item }
                  </Text>
                </HStack>
              ))}
            </UnorderedList>
          }
        </VStack>
      </Card>
    </WrapItem>
  )
}
