import * as React from "react";
import {
  Flex,
  Heading,
  Text
} from "@chakra-ui/react";

import { Card } from "../../common/Card";
import { IconFactory } from "../../common/ScalableVectorGraphics";


interface Props {
  heading?: string
  description?: string
  icon?: string

  // Node props
  node?: any
};


export const BenefitCard: React.FC<Props> = ( props ) => {
  return (
    <Card
      cardWidth={{
        base: '290px',
        lg: '230px',
        xl: '290px'
      }}
      cardHeight={{
        base: '380px',
        lg: '440px',
        xl: '380px'
      }}
      margin={{
        base: '0 20px',
        lg: '0'
      }}
      background="primary.100"
      borderRadius={{
        base: '24px'
      }}
      containerPadding="26px"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        w="64px"
        h="64px"
        background="white"
        borderRadius="8px"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.05)"
      >
        <IconFactory
          icon={ props.icon ? props.icon : props.node.frontmatter.icon }
          w="37px"
          h="auto"
          color="primary.900"
        />
      </Flex>
      <Heading
        as="h3"
        margin="52px 0 24px"
        color="accent.900"
        fontSize="28px"
        fontWeight={ 400 }
        lineHeight="33px"
      >
        { props.heading ? props.heading : props.node.frontmatter.heading }
      </Heading>
      <Text
        color="accent.900"
        lineHeight="21px"
      >
        { props.description ? props.description : props.node.frontmatter.description }
      </Text>
    </Card>
  )
}
