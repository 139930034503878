import * as React from "react";
import {
  Box,
  Container
} from "@chakra-ui/react";


interface Props {
  // Global components/props
  children: React.ReactNode
  flex?: object | string
  cardWidth?: object | string
  cardHeight?: object | string
  margin?: object | string
  background?: string
  borderRadius?: object | string
  boxShadow?: object | string

  // Container props
  containerPadding?: string
};


export const Card: React.FC<Props> = ( props ) => {
  return (
    <Box
      flex={ props.flex }
      position="relative"
      minW={ props.cardWidth }
      w={ props.cardWidth }
      minH={ props.cardHeight }
      h={ props.cardHeight }
      margin={ props.margin }
      background={ props.background }
      borderRadius={ props.borderRadius }
      boxShadow={ props.boxShadow }
    >
      <Container
        maxW="100%"
        w="100%"
        h="100%"
        padding={ props.containerPadding }
      >
        { props.children }
      </Container>
    </Box>
  )
}
