import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Container,
  Flex
} from "@chakra-ui/react";

import { VesterIcon } from "../../common/ScalableVectorGraphics";
import { SectionContent } from "../../common/Section";


interface Props {
  heading: string
};


export const LargeCallToAction: React.FC<Props> = ( props ) => {
  return (
    <Container
      position="relative"
      maxW={{
        base: '100%',
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px'
      }}
      w="100%"
      padding="0 1rem"
      overflow="hidden"
    >
      <Box
        position="absolute"
        top="50%"
        left={{
          base: '-19.5%',
          sm: '0',
          xl: '10.2%',
          '2xl': '16.2%'
        }}
        h="100%"
        transform={{
          base: 'translateY(-45%)',
          sm: 'translateY(-50%)',
          lg: 'translateY(-67%)'
        }}
        zIndex={ 0 }
      >
        <VesterIcon
          h={{
            base: '85%',
            sm: '100%',
            lg: '811px'
          }}
          w="auto"
          color="white"
        />
      </Box>
      <Flex
        justifyContent="center"
        w="100%"
        h={{
          lg: '646px'
        }}
        margin="0"
        padding={{
          base: '64px 0',
          lg: '0'
        }}
        zIndex={ 0 }
      >
        <SectionContent
          justifyContent="center"
          alignItems="left"
          componentWidth={{
            lg: '960px'
          }}
          textAlign="left"
          heading={ props.heading }
          Button={
            <Box>
              <Button
                as={ GatsbyLink }
                to="/estimate"
                variant="solid"
                size="lg"
                colorScheme="primary"
                border="1px"
                borderColor="primary.900"
                borderRadius="24px"
                _hover={{
                  background: 'transparent',
                  color: 'primary.900'
                }}
              >
                Request a quote
              </Button>
            </Box>
          }
        />
      </Flex>
    </Container>
  )
}
