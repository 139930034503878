import * as React from "react";
import {
  Flex
} from "@chakra-ui/react";

import { Carousel } from "../../common/Carousel";

import { BlogPostSlide } from "../Carousel";


interface Props {
  heading?: string
  description?: string[]
  nodes: any[]
};

export const FeaturedBlogPostsCarousel: React.FC<Props> = ( props ) => {
  return (
    <>
      <Carousel
        options={{
          dragFree: true,
          containScroll: "trimSnaps"
        }}
        display={{
          base: 'block',
          lg: 'none'
        }}
        emblaPadding="64px 0 0"
      >
        { props.nodes.map(( node: any, _index: number ) => {
          if ( node.node.frontmatter.featured ) { 
            return (  
              <BlogPostSlide
                key={ _index }
                heading={ node.node.frontmatter.title }
                description={ node.node.frontmatter.title }
                postDate={ node.node.frontmatter.postDate }
                image={ node.node.frontmatter.heroImage.childImageSharp.gatsbyImageData }
                slug={ node.node.fields.slug }
              />
            )
          } else {
            return null;
          }
        })}
      </Carousel>
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        display={{
          base: 'none',
          lg: 'flex'
        }}
        w="100%"
      >
        { props.nodes.map(( node: any, _index: number ) => {
          if ( node.node.frontmatter.featured ) { 
            return (  
              <BlogPostSlide
                key={ _index }
                heading={ node.node.frontmatter.title }
                description={ node.node.frontmatter.title }
                postDate={ node.node.frontmatter.postDate }
                image={ node.node.frontmatter.heroImage.childImageSharp.gatsbyImageData }
                slug={ node.node.fields.slug }
              />
            )
          } else {
            return null;
          }
        })}
      </Flex>

    </>
  )
}
