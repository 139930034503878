import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react";

import { LinkCard } from "../../common/Card";
import { ArrowRightIcon } from "../../common/ScalableVectorGraphics";


interface Props {
  node: any
};


export const ServiceCard: React.FC<Props> = ( props ) => {
  return (
    <LinkCard
      linkBoxWidth={{
        base: '290px'
      }}
      linkBoxHeight={{
        base: '468px'
      }}
      margin={{
        base: '0 1rem',
        lg: '0'
      }}
    >
      <Image
        as={ GatsbyImage }
        image={ props.node.frontmatter.heroImage.childImageSharp.gatsbyImageData }
        position="absolute"
        w="100%"
        h="100%"
        objectFit="cover"
      />
      <Box
        position="absolute"
        top={ 0 }
        left={ 0 }
        w="100%"
        h="100%"
        background="linear-gradient(transparent 0%, transparent 50%, black 100%)"
      />
      <Flex
        position="absolute"
        top={ 0 }
        left={ 0 }
        flexDirection="column"
        justifyContent="flex-end"
        w="100%"
        h="100%"
      >
        <VStack
          alignItems="left"
          spacing={ 2 }
          margin="26px"
          color="white"
          zIndex={ 10 }
        >
          <Heading
            as="h3"
            fontSize="28px"
            fontWeight={ 400 }
            lineHeight="33px"
          >
            <LinkOverlay
              as={ GatsbyLink }
              to={ props.node.fields.slug }
            >
              { props.node.frontmatter.cardHeading }
            </LinkOverlay>
          </Heading>
          <Text
            fontSize="12px"
            lineHeight="16px"
          >
            { props.node.frontmatter.cardDescription }
          </Text>
          <HStack
            spacing={ 2 }
          >
            <Text
              fontSize="12px"
              lineHeight="16px"
            >
              Learn more
            </Text>
            <ArrowRightIcon
              w="6px"
              h="8px"
            />
          </HStack>
        </VStack>
      </Flex>
    </LinkCard>
  )
}
