import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import useEmblaCarousel from "embla-carousel-react";
import {
  Box,
  Container,
  Flex,
  HStack,
  IconButton
} from "@chakra-ui/react";

import { EmblaOptionsType } from "embla-carousel-react";

import {
  BenefitCard,
  ServiceCard,
  TestimonialCard
} from "../../components/Card";

import {
  ArrowLeftIcon,
  ArrowRightIcon
} from "../ScalableVectorGraphics";


const EmblaDot = styled.button( props => ({
  backgroundColor: 'transparent',
  cursor: 'pointer',
  position: 'relative',
  padding: 0,
  outline: 0,
  border: 0,
  width: `calc(100% / ${ props.numItems })`,
  height: '2rem',
  margin: 0,
  display: 'flex',
  alignItems: 'center',

  '&:after': {
    backgroundColor: '#F94E1926',
    width: '100%',
    height: '4px',
    content: "",
  },

  '&.is-selected:after': {
    backgroundColor: '#F94E19',
    opacity: 1
  },
}));


const DotButton = ({
  selected,
  onClick,
  numItems
}: any) => {
  return (
    <EmblaDot
      className={ selected ? "is-selected" : "" }
      type="button"
      onClick={ onClick }
      numItems={ numItems }
    />
  )
};


interface Props {
  children?: React.ReactNode
  layout?: "testimonial" | "benefit" | "service"
  nodes?: any[]
  options: EmblaOptionsType
  buttonsEnabled?: boolean
  dotsEnabled?: boolean
  display?: object | string

  // EmblaContainer props
  emblaPadding?: object | string
};


export const Carousel: React.FC<Props> = ( props ) => {
  const [viewportRef, embla] = useEmblaCarousel( props.options );
  const [prevButtonEnabled, setPrevButtonEnabled] = useState( false );
  const [nextButtonEnabled, setNextButtonEnabled] = useState( false );
  const [selectedIndex, setSelectedIndex] = useState( 0 );
  const [scrollSnaps, setScrollSnaps] = useState( [] ) as any[];

  const scrollPrev = useCallback( () => embla && embla.scrollPrev(), [embla] );
  const scrollNext = useCallback( () => embla && embla.scrollNext(), [embla] );
  const scrollTo = useCallback( ( index ) => embla && embla.scrollTo( index ), [embla] );

  const onSelect = useCallback(() => {
    if ( !embla ) return;

    setSelectedIndex( embla.selectedScrollSnap() );
    setPrevButtonEnabled( embla.canScrollPrev() );
    setNextButtonEnabled( embla.canScrollNext() );
  }, [embla, setSelectedIndex] );

  useEffect( () => {
    if ( !embla ) return;

    onSelect();
    setScrollSnaps( embla.scrollSnapList() );

    embla.on( "select", onSelect );
  }, [embla, setScrollSnaps, onSelect] );

  return (
    <Box
      display={ props.display }
    >
      <Box className="Embla"
        position="relative"
        w="100%"
      >
        <Box className="EmblaViewport"
          ref={ viewportRef }
          overflow="hidden"
          w="100%"
          _hover={{
            cursor: 'grab'
          }}
          _active={{
            cursor: 'grabbing'
          }}
        >
          <Flex className="EmblaContainer"
            padding={ props.emblaPadding }
            userSelect="none"
            css={{
              WebkitTouchCallout: 'none',
              KhtmlUserSelect: 'none',
              WebkitTapHighlightColor: 'transparent'
            }}
          >
            { props.nodes ? props.nodes.map(( node, _index ) => {
              switch ( props.layout ) {
                case 'benefit':
                  return <BenefitCard key={ _index } node={ node.node } />;
                case 'service':
                  return <ServiceCard key={ _index } node={ node.node } />;
                default:
                  return null;
              }
            }) : null }
            { props.children ? props.children : null }
          </Flex>
        </Box>
      </Box>
      { props.dotsEnabled &&
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px'
          }}
          w="100%"
          padding="0 1rem"
        >
          <Flex
            listStyle="none"
            justifyContent="center"
          >
            { scrollSnaps.map( ( _: any, index: number ) => {
              <DotButton
                key={ index }
                selected={ index === selectedIndex }
                onClick={ () => scrollTo( index ) }
                numItems={ scrollSnaps.length }
              />
            })}
          </Flex>
        </Container>
      }
      { props.buttonsEnabled &&
        <Container
          maxW={{
            base: '100%',
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1536px'
          }}
          w="100%"
          padding="0 1rem"
        >
          <HStack
            spacing={ 2 }
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              aria-label="Previous slide"
              icon={ <ArrowLeftIcon w="6px" h="8px" /> }
              enabled={ prevButtonEnabled }
              onClick={ scrollPrev }
              background="transparent"
              color="accent.900"
              borderRadius="50%"
              boxShadow="0px 4px 24px rgba(0, 0, 0, 0.06)"
              _hover={{
                color: 'primary.900'
              }}
            />
            <IconButton
              aria-label="Next slide"
              icon={ <ArrowRightIcon w="6px" h="8px" /> }
              enabled={ nextButtonEnabled }
              onClick={ scrollNext }
              background="transparent"
              color="accent.900"
              borderRadius="50%"
              boxShadow="0px 4px 24px rgba(0, 0, 0, 0.06)"
              _hover={{
                color: 'primary.900'
              }}
            />
          </HStack>
        </Container>
      }
    </Box>
  )
}
